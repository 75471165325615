<template>
  <form @submit="$event.preventDefault()">
      <div
        class="form__wrapper"
        v-for="field in radioList"
        :class="{ active: value.select.value === field.value }"
      >
        <label class="form__label pointer">
          <input
            :name="formData.name"
            type="radio"
            :value="field"
            :checked="value.select.value === field.value"
            @change="value = field"
            @input="radioChange($event)"
            hidden="hidden"
          >
          <span class="form__checkerRadio"></span>
          <span class="text_smd">{{ field.label }} млн руб.</span>
        </label>
      </div>

    <div v-if="error" class="alert alert-danger" v-html="error"></div>

    <div class="content__buttonBox">
      <button
              v-if="!isFirst"
              @click="$store.commit('prev')"
              class="button_def button__size button__type_2"
      >Назад
      </button>

      <div class="growOn"></div>

      <button
              :disabled="!isValid || btnLock"
              @click="getResult"
              class="button_def button__size button__type_1 text_md medium "
      >
        {{ isLast ? 'Показать результат' : 'Следующий вопрос' }}
      </button>
    </div>
  </form>
</template>

<script>
import PriceFormRadioComponent from '@/modules/quiz/components/PriceFormRadioComponent'

export default {
  name: 'price-form-radio',
  mixins: [PriceFormRadioComponent],
  data(){
    return {
      btnLock: false
    }
  },
  methods: {
    getResult() {
      this.isLast ? this.btnLock = true : this.btnLock = false;
      this.$store.dispatch('getTotalWithNovos').then(() => {
        if (this.result) {
          this.$store.commit('next');
          this.btnLock = false
        }
      });
    },
    radioChange() {
      // setTimeout(() => {
      //   this.$store.dispatch('getTotalWithNovos').then(() => {
      //     if (this.result) {
      //       this.$store.commit('next');
      //     }
      //   });
      // }, 1600);
    },
  }
}
</script>
